<template>
  <default-layout :showBackBtn="false">
    <div class="borrower_exigency p-7 pt-0 text-center">
      <img src="@/assets/images/bgLogin.svg" class="my-4 inline h-44" alt />
      <div class="font-bold text-2xl mt-2 mb-5">
        {{ translate('Login') }}
      </div>
      <form
        @submit.prevent="handleSubmit"
        :novalidate="true"
        class="borrower_exigency_form"
      >
        <div class="text-center">
          <base-input
            v-model="phoneNumber"
            :placeholder="
              translate('Enter your :resource', {
                resource: translate('Phone').toLowerCase(),
              })
            "
            length="10"
            text-center
            type-input="text"
            mode="numeric"
            :spacing="!!phoneNumber"
            class="mt-2 text-field name text-base"
            @change="errorPhone = ''"
          />
          <base-text-error :errorMessages="errorPhone" />
          <div class="relative">
            <base-input
              v-model="password"
              :placeholder="
                translate('Enter your :resource', {
                  resource: translate('Pass').toLowerCase(),
                })
              "
              text-center
              class="mt-2 text-field name text-base"
              :type-input="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              @change="errorPass = ''"
            >
            </base-input>
            <div class="absolute right-0 top-0">
              <icon-eye-off v-if="showPass" @click="() => (showPass = false)" />
              <icon-eye v-else @click="() => (showPass = true)" />
            </div>
          </div>

          <base-text-error :errorMessages="errorPass" />
        </div>
        <base-button
          type="submit"
          class="my-4 shadow-3 h-10 w-full border-16 bg-tertiary text-white"
          :loading="loading"
        >
          {{ translate('Login') }}
        </base-button>
        <!-- <div class="py-2">
          <a class="text-tertiary font-medium hover:underline ">
            Quên mật khẩu?
          </a>
        </div> -->
        <div class="py-1">
          {{ translate("Don't Have an Account?") }}
          <router-link
            :to="{
              name: ConstRouter.BORROWER_MONEY.name,
            }"
            class="font-medium text-primary hover:underline text-lg"
          >
            {{ translate('Join now!') }}
          </router-link>
        </div>
      </form>
    </div>
  </default-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import IconEye from '@/components/Icon/IconEye.vue';
import IconEyeOff from '@/components/Icon/IconEyeOff.vue';
import ConstRouter from '@/constants/constRouter';
import translate from '@/helpers/localization';
import { required } from '@/helpers/validate';
import { mapActions, mapMutations } from 'vuex';
import { login } from '@/apis/ApiAuth';
import { setAuthToken } from '@/helpers/localStorage';
import constRouter from '@/constants/constRouter';

export default defineComponent({
  name: 'Login',
  components: {
    BaseInput,
    BaseButton,
    BaseTextError,
    DefaultLayout,
    IconEye,
    IconEyeOff,
  },
  data: () => ({
    ConstRouter,
    phoneNumber: '',
    password: '',
    errorPhone: '',
    errorPass: '',
    showPass: false,
    loading: false,
  }),
  methods: {
    translate,
    ...mapMutations({
      SET_INTEREST_FEE: 'SET_INTEREST_FEE',
    }),
    ...mapActions({
      setAnalyticsCategory: 'setAnalyticsCategory',
      fetchUserInfo: 'fetchUserInfo',
    }),
    checkValidate(): boolean {
      const isValid = this.phoneNumber && this.password;
      if (!isValid) {
        this.errorPhone = required('Phone', this.phoneNumber);
        this.errorPass = required('Pass', this.password);
        return false;
      }
      // phone number is not number
      if (!Number.isInteger(+this.phoneNumber)) {
        this.errorPhone = translate('":resource" is invalid', {
          resource: translate('Phone'),
        });
        return false;
      }
      // phone number is not enough 10 character
      if (this.phoneNumber.length !== 10) {
        this.errorPhone = translate('":resource" is not enough 10 numbers', {
          resource: translate('Phone'),
        });
        return false;
      }
      return true;
    },
    onClickShowPass() {
      console.log('a');
    },
    handleSubmit() {
      // clear error
      this.errorPhone = '';
      this.errorPass = '';
      if (this.checkValidate()) {
        this.callApi();
      }
    },
    callApi() {
      this.loading = true;
      const { phoneNumber, password } = this;
      login({ username: phoneNumber, password })
        .then(res => {
          this.loading = false;
          setAuthToken(res.data.token);

          // const companyId = +res.data?.personal_income?.company?.id;
          // this.setAnalyticsCategory(companyId);

          this.fetchUserInfo().then(() => {
            this.$router.push({
              name: constRouter.DASHBOARD.name,
            });
          });
        })
        .catch(err => {
          this.loading = false;

          // 400 err
          if (err?.status === 401 || err?.status === 422) {
            this.errorPhone = translate(
              'Phone number or password is incorrect',
            );
          } else {
            // err null, 500 error, disconnected
            this.errorPhone = translate('An unexpected error has occurred');
          }
        });
    },
  },
});
</script>
