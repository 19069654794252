
import { defineComponent } from 'vue';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import IconEye from '@/components/Icon/IconEye.vue';
import IconEyeOff from '@/components/Icon/IconEyeOff.vue';
import ConstRouter from '@/constants/constRouter';
import translate from '@/helpers/localization';
import { required } from '@/helpers/validate';
import { mapActions, mapMutations } from 'vuex';
import { login } from '@/apis/ApiAuth';
import { setAuthToken } from '@/helpers/localStorage';
import constRouter from '@/constants/constRouter';

export default defineComponent({
  name: 'Login',
  components: {
    BaseInput,
    BaseButton,
    BaseTextError,
    DefaultLayout,
    IconEye,
    IconEyeOff,
  },
  data: () => ({
    ConstRouter,
    phoneNumber: '',
    password: '',
    errorPhone: '',
    errorPass: '',
    showPass: false,
    loading: false,
  }),
  methods: {
    translate,
    ...mapMutations({
      SET_INTEREST_FEE: 'SET_INTEREST_FEE',
    }),
    ...mapActions({
      setAnalyticsCategory: 'setAnalyticsCategory',
      fetchUserInfo: 'fetchUserInfo',
    }),
    checkValidate(): boolean {
      const isValid = this.phoneNumber && this.password;
      if (!isValid) {
        this.errorPhone = required('Phone', this.phoneNumber);
        this.errorPass = required('Pass', this.password);
        return false;
      }
      // phone number is not number
      if (!Number.isInteger(+this.phoneNumber)) {
        this.errorPhone = translate('":resource" is invalid', {
          resource: translate('Phone'),
        });
        return false;
      }
      // phone number is not enough 10 character
      if (this.phoneNumber.length !== 10) {
        this.errorPhone = translate('":resource" is not enough 10 numbers', {
          resource: translate('Phone'),
        });
        return false;
      }
      return true;
    },
    onClickShowPass() {
      console.log('a');
    },
    handleSubmit() {
      // clear error
      this.errorPhone = '';
      this.errorPass = '';
      if (this.checkValidate()) {
        this.callApi();
      }
    },
    callApi() {
      this.loading = true;
      const { phoneNumber, password } = this;
      login({ username: phoneNumber, password })
        .then(res => {
          this.loading = false;
          setAuthToken(res.data.token);

          // const companyId = +res.data?.personal_income?.company?.id;
          // this.setAnalyticsCategory(companyId);

          this.fetchUserInfo().then(() => {
            this.$router.push({
              name: constRouter.DASHBOARD.name,
            });
          });
        })
        .catch(err => {
          this.loading = false;

          // 400 err
          if (err?.status === 401 || err?.status === 422) {
            this.errorPhone = translate(
              'Phone number or password is incorrect',
            );
          } else {
            // err null, 500 error, disconnected
            this.errorPhone = translate('An unexpected error has occurred');
          }
        });
    },
  },
});
